import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import settings from '@/settings.js'
import { getToken, getOSToken } from '@/utils/auth'

function IsIOS() {
    let OS = getOSToken();

    if (OS && OS.toLowerCase() === 'ios') {
        return true;
    }

    return false;
}

// create an axios instance
const service = axios.create({
    baseURL: IsIOS() ? settings.urlServiceBase_ios : settings.urlServiceBase,
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['admin-token'] = getToken();
        }

        // set request header OS information (example: Android, iOS,...)
        config.headers['x-game-os'] = getOSToken();

        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => response,
    error => {
        return Promise.reject(`${error.response.status} -> ${error.response.data.CodeMessage}`);
    }
)

export default service
