import request from '@/utils/request'
import requestServiceBase from "@/utils/request-service-base";

export function sendMail(mailData) {
    return request({
        url: '/inbox',
        method: 'post',
        data: mailData
    })
}

export async function sendMailAsync(mailData) {
    let response = await requestServiceBase({
        url: `/maintain/mailbox/add-mailbox`,
        method: 'post',
        data: mailData,
    });

    return response.data;
}

//{creator,title,content,user_filters,rewards,date_begin,date_end}
export async function sendMailGroup(mailGroupData) {
    let response = await requestServiceBase({
        url: '/maintain/mailbox/add-mailgroup',
        method: 'post',
        data: mailGroupData
    })

    return response.data;
}

export async function getInboxAsync(userID, start, limit) {
    let response = await requestServiceBase({
        url: `/maintain/mailbox/get-mail/${userID}`,// + '?start=' + start + '&limit=' + limit,
        method: 'get'
    });

    return response.data;
}

export function getTrackingUsers() {
    return request({
        url: '/inbox/tracking_users/all',
        method: 'get'
    })
}

export function getTrackingUserMail(userID) {
    return request({
        url: '/inbox/tracking_users_mail/' + userID,
        method: 'get'
    })
}

export function clearTrackingUserMail(userID, mailID) {
    return request({
        url: '/inbox/delete_tracking_users_mail/' + userID + "/" + mailID,
        method: 'get'
    })
}