import request from '@/utils/request'
import requestServiceBase from "@/utils/request-service-base";

export async function getMailGroupListAsync() {
    let response = await requestServiceBase({
        url: '/maintain/mailbox/get-list-mailgroup',
        method: 'get'
    })

    return response.data;
}

export async function updateMailGroup(mailGroupData) {
    let response = await requestServiceBase({
        url: '/maintain/mailbox/update-mailgroup',
        method: 'post',
        data: mailGroupData
    })

    return response.data;
}

export async function deleteMailGroup(dataID) {
    let response = await requestServiceBase({
        url: '/maintain/mailbox/delete-mailgroup',
        method: 'post',
        data: dataID
    })

    return response.data;
}